<template>
  <Section class="section--border-bottom bg-gray-lightestest">
    <Container>
      <Timeline
        :index="step"
        :total="3"
        :lastStepToRight="false"
        :stepsData="stepsData"
      />
    </Container>
  </Section>
  <router-view @set-step="setStep" v-model="submit" @set-route="
            (event) => {
              previousRouteName = event.previousRouteName;
              routeParams = event.routeParams;
            }
          "></router-view>
  <div class="view-footer">
    <template
      v-if="
        $route.name.includes('Import') &&
        !$route.name.includes('Export')
      "
    >
      <div class="view-actions view-actions--left">
        <Btn v-if="!$route.name.includes('Resume')" text="Annuler" :to="{ name: 'essaiImportExport', params: { id: $route.params.id} }" />
        <template v-if="$route.name.includes('Report') || $route.name.includes('Data')">
          <Btn
            v-if="previousRouteName && step !== 0"
            :to="{ name: previousRouteName, params: routeParams }"
            text="Précédent"
            icon="chevron-big-left"
          />
        </template>
        <template v-if="step === 0">
          <Btn
            @click="submit = true"
            text="Suivant"
            color="primary"
            icon="chevron-big-right"
            icon-after
          />
        </template>
        <template v-if="step === 1">
          <Btn
            @click="submit = true"
            text="Valider et importer les données"
            color="primary"
            icon-after
          />
        </template>
        <template v-if="$route.name.includes('Resume')">
          <Btn
            text="Retour à la liste"
            color="primary"
            icon="check"
            icon-after
            :to="{ name: 'essaiImportExport', params: { id: $route.params.id} }"
          />
        </template>
      </div>
    </template>
  </div>

</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Timeline from '@/components/layout/Timeline.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'EssaiImportView',

  components: {
    Section,
    Container,
    Timeline,
    Btn,
  },

  emits: ['updateEssais'],

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
    id: {
      type: [Number, String],
    },
  },

  data() {
    return {
      previousRouteName: null,
      routeParams: {},
      sheets: [],
      variables: [],
      flatVariables: [],
      jeton: null,
      selectedVariables: {},
      step: 0,
      stepsData: ['Assignation des variables', 'Rapport préliminaire', 'Récapitulatif'],
      submit: false,
      modalData: {},
      modal: {
        selectVariable: false,
      },
      componentKey: 0,
    }
  },

  methods: {
    setStep(event) {
      this.step = event
    },
  },

}
</script>

<style lang="scss" scoped>

  .table--trial-import {
    ::v-deep(table) {
      th,
      td {
        &:first-child {
          border-left: 1px solid $color-primary-dark;
        }
      }

      tr:first-child {
        th:first-child {
          position: initial;
          font-size: $font-size-big;
          text-transform: uppercase;
          background-color: $color-primary-dark;
        }
        th:not(:first-child) {
          // outline: 4px dashed blueviolet;
          // max-width: 40rem;
          // white-space: break-spaces;
        }
      }
    }

    .table-col-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $gutter-half;
      text-align: left;
    }
  }

  .input-file--import {
    padding-right: $gutter-half;
    padding-left: 5.2rem + $gutter-half;
    @include v-padding($gutter-half);

    @include bp('sm') {
      display: flex;
      align-items: center;
      gap: $gutter;

      ::v-deep(label) {
        margin-bottom: 0;
        font-size: $font-size-big;
      }

      ::v-deep(.input) {
        flex-grow: 1;
        flex-direction: row;
        justify-content: flex-start;
        gap: $gutter-half;
      }

      ::v-deep(.input-file-filename) {
        margin-bottom: 0;
      }
      ::v-deep(.input-file-label) {
        margin-bottom: 0;
      }
    }

    @include bp($bp-expanded-container) {
      padding-right: $gutter;
      padding-left: 5.2rem + $gutter;
    }
  }
  .essai-statut {
    height: 15px;
    width: 15px;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    transform: rotate(45deg);

    &--filled {
      border: 1px solid $color-primary;
      background: $color-primary;
    }
  }

  .essai-bpe {
    height: 25px;
    width: 25px;
    border: 10px solid $color-primary;
    border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: $font-size-smaller;
    font-weight: $font-weight-lighter;
  }

  .essai-check {
    height: 40px;
    width: 40px;
    border: 2px solid $color-primary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary;
  }
</style>
